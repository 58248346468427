/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-o5b912 css-1niwrs4 --style3 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen_s=3000x_.jpg);
    }
  
background-position: 55% 6%;
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" anim={null} style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--72 w--900 title-box--invert" style={{"maxWidth":700}} content={"Vyplatíme Vám financie&nbsp; ešte pred predajom"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape4 fs--22 mt--30" use={"page"} href={"/kontakt#kontakt"} target={"_blank"} content={"Kontaktovat"}>
              </Button>

              <Text className="text-box text-box--left fs--72" content={"<span style=\"color: var(--white); font-weight: bold;\">0907 865 803</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" anim={null} name={"informace"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--3 --full flex--center" anim={""} animS={"3"} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box mt--08 pb--30" style={{"maxWidth":1000}} content={"<span style=\"color: rgb(25, 53, 125);\">Okamžitý výkup</span><br>"}>
              </Title>

              <Text className="text-box mb--0" content={"Vykúpime Vašu nehnuteľnosť, rýchlo a za férovú cenu. Vykupujeme nehnuteľnosti v rámci celej Slovenskej republiky a Českej republiky."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box pt--60" content={"<span style=\"color: var(--color-custom-1);\">Peniaze ešte pred predajom</span>"}>
              </Title>

              <Text className="text-box" content={"Potrebujete určitú finančnú čiastku ihneď a zároveň chcete dostať za nehnuteľnosť najvyššiu možnú sumu? My Vám vyplatíme dohodnutú čiastku vopred a nehnuteľnosť môžete ďalej predávať za trhovú cenu. S predajom nehnuteľností Vám radi pomôžeme."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box pt--0" content={"<span style=\"color: var(--color-custom-1);\">Zbavíme Vás dlhov a zostanete bývať vo vlastnom</span>"}>
              </Title>

              <Text className="text-box" content={"Dostali ste sa do dlhovej pasce, máte exekúcie alebo Vám hrozí dražba a nechcete prísť o svoje bývanie? Využite našu službu spätného leasingu nehnuteľnosti. Vaše dlhy vyplatíme a Vy budete môcť naďalej zostať bývať u seba doma."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--50" name={"o-mne"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2 flex--top" anim={"2"} animS={"3"} style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--48" content={"World Capital Trade - LL"}>
              </Title>

              <Text className="text-box fs--18 w--900 ls--002" style={{"maxWidth":650}} content={"100% Dôvera&nbsp; &nbsp; &nbsp;⭐ ⭐ ⭐ ⭐ ⭐"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":650}} content={"Máme viac ako 15 ročné skúsenosti v realitnom a finančnom trhu. S klientom vždy jednáme férovo a nič nezamlčíme. Za nás hovoria skutky a plno spokojných klientov.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/i/template/132/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/132/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/132/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/132/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/132/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/132/img-1_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--16 pt--16" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--center" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-1.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Výkup nehnuteľností"}>
              </Subtitle>

              <Text className="text-box" content={"Vykúpime Vašu nehnuteľnosť, rýchlo a za férovú cenu. Vykupujeme nehnuteľnosti v rámci celej Slovenskej republiky a Českej republiky."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 --left pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"marginTop":89,"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-3.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Vyplatíme Vám financie pred predajom"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":1000}} content={"Potrebujete určitú finančnú čiastku ihneď a zároveň chcete dostať za nehnuteľnosť najvyššiu možnú sumu? My Vám vyplatíme dohodnutú čiastku vopred a nehnuteľnosť môžete ďalej predávať za trhovú cenu. S predajom nehnuteľností Vám radi pomôžeme."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-2.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Zbavíme Vás dlhov a zostanete bývať vo vlastnom"}>
              </Subtitle>

              <Text className="text-box" content={"V prípade ak ste sa dostali do dlhovej pasce, hrozí Vám alebo už máte exekúciu, prípadne vyhlásenú dražbu, tak stále Vám vieme nehnuteľnosť zachrániť. V tomto prípade nás treba kontaktovať urgentne."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-4.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Zmluvy vypracované advokátskou kanceláriou"}>
              </Subtitle>

              <Text className="text-box" content={"Všetky zmluvy sú vypracované za účasti advokátskej kancelárie, tak aby sme Vám mohli garantovať bezpečný priebeh celého procesu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-2.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Celý priebeh pod dohľadom notára"}>
              </Subtitle>

              <Text className="text-box" content={"Celý proces prebieha, pod notársky dohľadom, aby ste mali zaručené bezpečie a nemuseli sa ničoho obávať."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape4 js-anim  --anim2 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Image src={"https://cdn.swbpg.com/o/g/Ikonky/Finance/finanfce-5.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":96}} srcSet={""} svgColor={"rgba(25,53,125,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Garantujeme hladký priebeh"}>
              </Subtitle>

              <Text className="text-box" content={"Sme tím profesionálov, s dlhoročnou praxou, preto Vám vieme zaručiť hladký priebeh a maximálnu spokojnosť."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="--center pb--20 pt--20" name={"reference"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"reference-2"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1200}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--color-dominant);\">Referencie</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3" anim={"4"} animS={"3"}>
              
              <Text className="text-box text-box--left" content={"<span style=\"color: var(--color-dominant);\">\"Potrebovali sme rýchlo predať nehnuteľnosť ale nechceli sme ísť pod cenu, oslovili sme spoločnosť World Capital Trade. Ešte v ten istý deň urobili obhliadku, dali nám ponuku, ktorú sme si mali čas premyslieť a keď sme sa rozhodli ponuku na výkup bytu prijať, tak celý proces predaja prebehol veľmi rýchlo a férovo. \"</span>"}>
              </Text>

              <Text className="text-box text-box--left w--900" content={"<span style=\"color: var(--color-dominant);\">—   Veronika Kostolanská&nbsp; &nbsp; &nbsp;⭐ ⭐ ⭐ ⭐ ⭐</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3" anim={"4"} animS={"3"}>
              
              <Text className="text-box text-box--left" content={"<span style=\"color: var(--color-dominant);\">\"Mal som rôzne dlhy, exekúcie a pôžičky, ktoré som nevládal splácať a hrozilo, že prídem v dražbe, kvôli exekúciám o byt, tak som oslovil spoločnosť World Capital Trade - LL a bol som veľmi príjemne prekvapený. Reagovali veľmi rýchlo, celú komunikáciu s exekútormi a nebankovami vybavali za mňa, vyplatili všetky dlhy a neprišiel som o bývanie.\"</span>"}>
              </Text>

              <Text className="text-box text-box--left w--900" content={"<span style=\"color: var(--color-dominant);\">—   Dávid Nosko&nbsp; &nbsp; ⭐ ⭐ ⭐ ⭐ ⭐</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3" anim={"4"} animS={"3"}>
              
              <Text className="text-box text-box--left" content={"<span style=\"color: var(--color-dominant);\">\"Rozhodli sme sa, že predáme náš dom ale zároveň sme potrebovali&nbsp; rýchlo určitú čiastku hotovosti na kúpu iného domu, preto sme oslovili spoločnosť World Capital Trade. Táto spoločnosť nám vyplatila dohodnutú finančnú čiastku vopred, ešte pred predajom, následne nám pomohli s predajom nášho domu a bol nám vyplatený zvyšok financií. Táto služba je naozaj skvelá a veľmi pekne ďakujeme za pomoc pri predaji domu,\"</span>"}>
              </Text>

              <Text className="text-box text-box--left w--900" content={"<span style=\"color: var(--color-dominant);\">—   Zuzana Beláková&nbsp; &nbsp; &nbsp;⭐ ⭐ ⭐ ⭐ ⭐</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-g943z5 pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Vykúpime Vašu nehnuteľnosť rýchlo a férovo.</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape4" use={"page"} href={"/kontakt#kontakt"} target={"_blank"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"World Capital Trade - LL"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"+421907865803"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}